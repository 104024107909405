import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { Links, Select, HiddenList } from './styles';

class ResponsiveMenu extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    items: PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        })
      ),
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    ]).isRequired,
    containerWidth: PropTypes.number,
    getActiveLink: PropTypes.func,
    renderLink: PropTypes.func.isRequired,
    renderHiddenLink: PropTypes.func.isRequired,
    renderMoreBtn: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    defaultItemsCount: PropTypes.number,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    containerWidth: null,
    getActiveLink: () => {},
    defaultItemsCount: 2,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items !== prevState.cachedItems) {
      const itemsArray = Array.isArray(nextProps.items)
        ? nextProps.items
        : Object.values(nextProps.items);

      return {
        items: itemsArray,
        cachedItems: nextProps.items,
        visibleItems: itemsArray.slice(0, nextProps.defaultItemsCount),
        hiddenItems: itemsArray.slice(
          nextProps.defaultItemsCount,
          Math.max(itemsArray.length, nextProps.defaultItemsCount)
        ),
      };
    }

    return null;
  }

  listRef = createRef();

  visibleItemsRefs = new Map();

  hiddenItemsRefs = new Map();

  moreLink = createRef();

  isMobile = false;

  // eslint-disable-next-line react/state-in-constructor
  state = {
    items: [],
    cachedItems: null,
    visibleItems: [],
    hiddenItems: [],
    hiddenItemsDimensions: [],
    isOpen: false,
  };

  componentDidMount() {
    this.updateVisibleLinksItems();
    window.addEventListener('touchend', this.touchToggle);
  }

  componentWillUnmount() {
    window.removeEventListener('touchend', this.touchToggle);
  }

  componentDidUpdate = () => {
    this.updateVisibleLinksItems();
  };

  updateVisibleLinksItems = () => {
    const { hiddenItemsDimensions, hiddenItems } = this.state;
    const { containerWidth } = this.props;

    if (containerWidth && document.body.clientWidth > 640) {
      const needHideLink =
        containerWidth && containerWidth <= this.listRef.current.clientWidth;

      const needShowLink =
        containerWidth &&
        !!hiddenItems.length &&
        containerWidth >
          this.listRef.current.clientWidth +
            (hiddenItemsDimensions[hiddenItemsDimensions.length - 1] || 0);

      if (needHideLink) {
        this.putLinkToHidden();
      }

      if (needShowLink) {
        this.getLinkFromHidden();
      }
    }
  };

  putLinkToHidden = () => {
    if (this.visibleItemsRefs.size > 0) {
      const lastDeletedLinkWidth = Array.from(this.visibleItemsRefs)[
        this.visibleItemsRefs.size - 1
      ][1].current.clientWidth;

      this.setState(({ visibleItems, hiddenItems, hiddenItemsDimensions }) => ({
        visibleItems: visibleItems.slice(0, -1),
        hiddenItems: [visibleItems[visibleItems.length - 1], ...hiddenItems],
        hiddenItemsDimensions: [...hiddenItemsDimensions, lastDeletedLinkWidth],
      }));
    }
  };

  getLinkFromHidden = () => {
    this.setState(({ visibleItems, hiddenItems, hiddenItemsDimensions }) => ({
      visibleItems: [...visibleItems, hiddenItems[0]],
      hiddenItems: hiddenItems.slice(1),
      hiddenItemsDimensions: hiddenItemsDimensions.slice(0, -1),
    }));
  };

  toggle = open => {
    this.setState(prevState => ({
      isOpen: open !== undefined ? open : !prevState.isOpen,
    }));
  };

  touchToggle = e => {
    if (e.currentTarget === this.moreLink.current) {
      this.toggle();
    }
  };

  render() {
    const { isOpen, visibleItems, hiddenItems } = this.state;
    const {
      getActiveLink,
      renderLink,
      renderHiddenLink,
      renderMoreBtn,
    } = this.props;

    this.isMobile = false;

    const activeLink = getActiveLink(this.props, this.state);

    this.visibleItemsRefs = new Map();

    this.hiddenItemsRefs = new Map();

    const commonProps = {
      state: this.state,
      props: this.props,
      active: activeLink,
    };

    return (
      <Links ref={this.listRef}>
        {visibleItems.map((item, index) => {
          const ref = createRef();
          this.visibleItemsRefs.set(index, ref);

          return renderLink({
            ...commonProps,
            item,
            ref,
            index,
          });
        })}

        {hiddenItems.length > 0 && (
          <Select
            onMouseEnter={() => this.toggle(true)}
            onMouseLeave={() => this.toggle(false)}
            open={isOpen}
          >
            {renderMoreBtn({
              ...commonProps,
              ref: this.moreLink,
              toggle: this.touchToggle,
            })}

            <HiddenList isOpen={isOpen}>
              {hiddenItems.map((item, index) => {
                const ref = createRef();
                this.hiddenItemsRefs.set(index, ref);

                return renderHiddenLink({
                  ...commonProps,
                  item,
                  ref,
                  index,
                });
              })}
            </HiddenList>
          </Select>
        )}
      </Links>
    );
  }
}

export default ResponsiveMenu;
