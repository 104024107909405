import styled from 'styled-components';

import { colorWhite, colorGrey40, mediaQueryMobileLargeMin } from 'styleUtils';

export const Container = styled.div`
  margin: 0 16px;
  border-top: 1px solid ${colorGrey40};
  border-bottom: 1px solid ${colorGrey40};
  position: relative;

  &:after {
    content: '';
    background: linear-gradient(
      270deg,
      ${colorWhite} 0%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 32px;
    height: 47px;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media ${mediaQueryMobileLargeMin} {
    border-top: none;

    &:after {
      display: none;
    }
  }
`;

export default Container;
