import React, { useEffect, useCallback, useMemo, memo } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Page from 'components/Page';
import PageInner from 'components/PageInner';
import PageContent from 'components/PageContent';
import PageSidebar from 'components/PageSidebar';
import LayoutContainer from 'components/LayoutContainer';
import BankCardItem from 'components/BankCardItem';
import BankCardListLoader from 'components/BankCardListLoader';
import OrganizationPageHeader from 'components/OrganizationPageHeader';
import NoData from 'components/NoData';
import PageFooter from 'components/PageFooter';

import { useSelectorProps } from 'hooks';
import { fetchProductsByFilters } from 'actions';
import {
  getCompanyWithProducts,
  getProductsIsLoaded,
  getProductsIsRequestFailed,
  getOrganizationIsRequestFailed,
  getProductsIsFetching,
} from 'selectors';

import { TextCountWrapper, TextCount } from 'components/ProductsList/styles';

import { ListWrapper, ProductsWrapper } from './styles';

function OrganizationPage({ loadData, staticContext }) {
  const selectorProps = useSelectorProps();
  const history = useHistory();

  const dispatch = useDispatch();

  const isLoaded = useSelector(state =>
    getProductsIsLoaded(state, selectorProps)
  );

  const isFetching = useSelector(state =>
    getProductsIsFetching(state, selectorProps)
  );

  const productaIsRequestFailed = useSelector(state =>
    getProductsIsRequestFailed(state, selectorProps)
  );

  const organizationIsRequestFailed = useSelector(state =>
    getOrganizationIsRequestFailed(state, selectorProps)
  );

  const isErrPage = useMemo(
    () => productaIsRequestFailed || organizationIsRequestFailed,
    [productaIsRequestFailed, organizationIsRequestFailed]
  );

  const visible = useSelector(state =>
    getCompanyWithProducts(state, selectorProps)
  );

  const productsCount = useMemo(() => visible.products.length, [visible]);

  const handleFetchProducts = useCallback(() => {
    dispatch(fetchProductsByFilters(selectorProps));
  }, [dispatch, selectorProps]);

  const handleResetFilters = useCallback(() => {
    history.push({ pathname: '/' });
  }, []);

  useEffect(() => {
    loadData(dispatch, selectorProps);
    window.scrollTo(0, 0);
  }, []);

  if (isErrPage && staticContext) {
    staticContext.status = 404;
  }

  return (
    <Page>
      <LayoutContainer>
        <PageInner>
          <PageContent>
            {isLoaded && (
              <>
                <OrganizationPageHeader {...visible} />

                <TextCountWrapper>
                  <TextCount>
                    <FormattedMessage
                      id="cardsListHeader.count"
                      values={{ count: productsCount }}
                    />
                  </TextCount>
                </TextCountWrapper>

                <ProductsWrapper>
                  {visible.products.map(item => (
                    <ListWrapper key={item.id}>
                      <BankCardItem card={item} />
                    </ListWrapper>
                  ))}
                </ProductsWrapper>
              </>
            )}

            {isFetching &&
              Array.from(Array(6)).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <BankCardListLoader key={index} hideCompanyDetails />
              ))}

            {(isErrPage || (!productsCount && isLoaded)) && (
              <NoData
                isRequestField={isErrPage}
                fetchData={handleFetchProducts}
                resetFilters={handleResetFilters}
              />
            )}
          </PageContent>
          <PageSidebar />
        </PageInner>
        <PageFooter />
      </LayoutContainer>
    </Page>
  );
}

OrganizationPage.propTypes = {
  loadData: PropTypes.func.isRequired,
  staticContext: PropTypes.objectOf(PropTypes.any),
};

OrganizationPage.defaultProps = {
  staticContext: null,
};

export default withRouter(memo(OrganizationPage));
