import styled from 'styled-components';

import { colorWhite } from 'styleUtils';

export const ProductsWrapper = styled.div`
  width: 100%;
  margin: 16px 0 0 0;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colorWhite};
  padding: 24px 16px 0 16px;

  &:not(:last-child) {
    margin: 0 0 8px 0;
  }
`;
