import styled from 'styled-components';

import IconComponent from 'components/Icon';
import Link from 'components/Link';

import {
  colorWhite,
  colorGreen400,
  colorBlue,
  colorGrey700,
  fontFamilyRaleway,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
  mediaQueryMobileMin,
  mediaQueryDesktopMin,
  mediaQueryTabletMin,
} from 'styleUtils';

export const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  margin: 0 0 24px 0;
  background: ${colorWhite};
`;

export const Banner = styled.div`
  position: relative;

  ${props => {
    const { img } = props;
    const gradient =
      'linear-gradient(180deg, rgba(0, 0, 0, 3e-05) 0%, rgba(13, 24, 84, 0.048) 100%)';

    if (img) {
      return `
        background-image: url(${process.env.APP_MINFIN_DOMAIN}${img});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 31.66%;
      `;
    }

    return `
      min-height: 40px;
      background: ${gradient};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      
      @media ${mediaQueryMobileLargeMin} {
        min-height: 90px;
      }
    `;
  }}
`;

export const MainInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colorWhite};
  padding: 16px;
  position: relative;

  @media ${mediaQueryMobileMin} {
    padding: 16px 16px 0;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media ${mediaQueryMobileMin} {
    width: 50%;
  }

  @media ${mediaQueryMobileLargeMin} {
    width: 65%;
  }
`;

export const Logo = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colorWhite};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.102893);
  border-radius: 4px;
  margin: -44px 24px 0 0;

  @media ${mediaQueryMobileMin} {
    width: 124px;
    height: 124px;
    margin: -92px 24px 0 0;
  }
`;

export const Img = styled.img`
  max-width: 52px;
  max-height: 52px;

  @media ${mediaQueryMobileMin} {
    max-width: 112px;
    max-height: 112px;
  }
`;

export const Name = styled.h1`
  font-family: ${fontFamilyRaleway};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colorGrey700};

  @media ${mediaQueryMobileMin} {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 50%;
  display: none;
  align-items: center;
  justify-content: flex-end;

  @media ${mediaQueryMobileMin} {
    display: flex;
  }

  @media ${mediaQueryMobileLargeMin} {
    width: 35%;
  }
`;

const Button = styled.a`
  padding: 4px 12px;
  border: 1px solid ${colorGreen400};
  box-sizing: border-box;
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colorGreen400};
  transition: 0.2s;

  &:hover {
    color: ${colorGreen400};
    opacity: 0.8;
  }
`;

export const LinkToSubscribe = styled(Button)`
  width: max-content;
  margin-right: 8px;
`;

export const LinkToReview = styled(Button)`
  width: max-content;
  font-weight: bold;
`;

export const Icon = styled(IconComponent)`
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
`;

export const FiltersFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  @media ${mediaQueryDesktopMin} {
    padding: 32px 16px;
    flex-direction: row;
  }
`;

export const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0;

  @media ${mediaQueryTabletMin} {
    flex-direction: row;
  }

  @media ${mediaQueryDesktopMin} {
    width: max-content;
    margin: 0;
  }
`;

export const AdvertiserNoteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BackToListWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: 1px solid rgba(36, 70, 150, 0.24);
  border-radius: 2px;
  margin: 12px 0 16px 0;
  transition: 0.2s;
  justify-content: center;

  svg {
    width: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  @media ${mediaQueryTabletMin} {
    margin: 0 0 0 16px;
    padding: 4px 16px;
  }
`;

export const BackToCatalog = styled(Link)`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${colorBlue};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: 1px solid rgba(36, 70, 150, 0.24);
  border-radius: 2px;
  margin: 12px 0 16px 0;
  transition: 0.2s;
  justify-content: center;

  svg {
    width: 8px;
  }

  &:hover {
    color: ${colorBlue};
  }

  @media ${mediaQueryTabletMin} {
    margin: 0 0 0 16px;
    padding: 4px 16px;
  }
`;

export const IconBack = styled(IconComponent)`
  width: 8px;
  margin: 0 0 0 8px;
`;
