import styled from 'styled-components';

import { colorRed, colorWhite, mediaQueryTabletMin } from 'styleUtils';

import Icon from 'components/Icon';

export const Links = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 16px 0;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${mediaQueryTabletMin} {
    display: inline-flex;
    overflow-y: inherit;
    max-width: 976px;
  }
`;

export const LinkIcon = styled(Icon)`
  margin-left: 4px;
  transition: transform 0.3s ease;
`;

export const Select = styled.li`
  display: inline-flex;
  position: relative;

  ${({ open }) => {
    if (open) {
      return `
      & > button {
        color: ${colorRed};
      }
      & > button > svg {
        transform: rotateZ(180deg);
      }
      `;
    }

    return '';
  }}
`;

export const HiddenList = styled.ul`
  background-color: ${colorWhite};
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  border: 1px solid #e3e3e3;
  right: 0;
  top: 100%;
  position: absolute;
  z-index: 5;
  overflow-y: auto;
  padding: 0;
`;
