import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Media from 'react-media';

import ResponsiveMenu from 'components/ResponsiveMenu';
import ContainerDimensions from 'components/ContainerDimensions';

import { ListItem, Link, LinkOptionItem, LinkOption, LinkIcon } from './styles';

const mobileQuery = { minWidth: 320 };

const ResponsiveMenuWrapper = memo(({ menu }) => {
  const { formatMessage } = useIntl();

  const renderLink = useCallback(
    ({ item, ref = () => {} }) => (
      <ListItem key={item.link} active={item.link === 'cards'} ref={ref}>
        <Link href={item.link} title={item.name}>
          {item.name}
        </Link>
      </ListItem>
    ),
    []
  );

  const renderHiddenLink = useCallback(
    ({ item }) => (
      <LinkOptionItem key={item.link}>
        <LinkOption
          href={item.link}
          title={item.name}
          active={item.link === 'cards'}
        >
          {item.name}
        </LinkOption>
      </LinkOptionItem>
    ),
    []
  );

  const renderMoreBtn = useCallback(
    ({ toggle, ref }) => {
      const moreTitle = formatMessage({
        id: `links.more`,
      });

      return (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            as="button"
            type="button"
            title={moreTitle}
            ref={ref}
            onTouchEnd={toggle}
          >
            {moreTitle}

            <LinkIcon name="arrow" />
          </Link>
        </>
      );
    },
    [formatMessage]
  );

  const renderDesktopMenu = useCallback(
    () => (
      <ContainerDimensions>
        {({ width }) => (
          <ResponsiveMenu
            items={menu}
            containerWidth={width}
            renderLink={renderLink}
            renderHiddenLink={renderHiddenLink}
            renderMoreBtn={renderMoreBtn}
            defaultItemsCount={100}
          />
        )}
      </ContainerDimensions>
    ),
    [menu]
  );

  return (
    <Media
      query={mobileQuery}
      defaultMatches={false}
      render={renderDesktopMenu}
    />
  );
});

ResponsiveMenuWrapper.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

ResponsiveMenuWrapper.defaultProps = {
  menu: null,
};

export default withRouter(ResponsiveMenuWrapper);
