import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import {
  addResizeEndListener,
  removeResizeEndListener,
  getDimensions,
} from 'utils';

import { Container } from './styles';

class ContainerDimensions extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    width: 0,
    height: 0,
    screenWidth: 0,
    screenHeight: 0,
  };

  wrapper = createRef();

  componentDidMount() {
    this.onResize();
    this.listener = addResizeEndListener(this.wrapper.current, this.onResize);
  }

  componentWillUnmount() {
    removeResizeEndListener(this.wrapper.current, this.listener);
  }

  onResize = () => {
    const { width, height } = getDimensions(this.wrapper.current);
    const {
      clientWidth: screenWidth,
      clientHeight: screenHeight,
    } = document.documentElement;

    this.setState({
      width,
      height,
      screenWidth,
      screenHeight,
    });
  };

  render() {
    const { children } = this.props;

    return <Container ref={this.wrapper}>{children(this.state)}</Container>;
  }
}

ContainerDimensions.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ContainerDimensions;
