import styled from 'styled-components';

import {
  colorBlue,
  colorRed,
  colorWhite,
  mediaQueryMobileLargeMin,
  mediaQueryDesktopMin,
} from 'styleUtils';
import Icon from 'components/Icon';

const colorBlueGray = '#d2d9e0';

export const MenuWrapper = styled.div`
  border-top: 1px solid ${colorBlueGray};
  border-bottom: 1px solid ${colorBlueGray};

  @media ${mediaQueryMobileLargeMin} {
    padding: 0 16px 8px;
    border: 0;
  }

  [data-item-index='0'] {
    padding-left: 8px;
  }

  [data-item-index='last'] {
    padding-right: 8px;
  }

  @media ${mediaQueryMobileLargeMin} {
    [data-item-index='0'] {
      padding-left: 0;
    }

    [data-item-index='last'] {
      padding-right: 0;
    }
  }
`;

export const LinksList = styled.ul`
  display: inline-flex;
  flex-wrap: nowrap;
  padding: 0 8px;

  @media ${mediaQueryDesktopMin} {
    padding: 0;
  }
`;

export const Link = styled.a`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorBlue};
  font-size: 14px;
  padding: 0 16px 0 0;
  transition: color 0.2s ease;
  border: 0;
  background: ${colorWhite};
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const underline = `
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 8px;
    right: 8px;
    height: 2px;
    z-index: 1;
    background-color: ${colorRed};
  }

  @media ${mediaQueryMobileLargeMin} {
    &:before {
      display: none;
    } 
`;

export const ListItem = styled.li`
  position: relative;
  list-style-type: none;

  ${({ active }) => {
    if (active) {
      return `
        ${Link} {
          color: ${colorRed}
        }

        ${underline}
      `;
    }

    return ``;
  }}

  ${({ mobile }) => {
    if (mobile) {
      return `
        &:hover {
          ${Link} {
            color: ${colorRed};
          }

          ${underline}
        }
      `;
    }

    return `
      &:hover {
        ${Link} {
          color: ${colorRed};
        }
      }
    `;
  }}
`;

export const LinkIcon = styled(Icon)`
  width: 8px;
  margin-left: 4px;
  transition: transform 0.3s ease;
`;

export const LinkOptionItem = styled.li`
  list-style-type: none;
  border-bottom: 1px solid #e3e3e3;
  padding: 8px;

  &:last-child {
    border-bottom: 0;
  }
`;

export const LinkOption = styled(Link)`
  padding: 10px 16px;
  line-height: initial;

  &::first-letter {
    text-transform: uppercase;
  }

  ${({ active }) => {
    if (active) {
      return `
        color: ${colorRed}
      `;
    }

    return ``;
  }}
`;
