import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import PageHelmet from 'components/PageHelmet';
import ResponsiveMenuWrapper from 'components/ResponsiveMenuWrapper';

import { META_FOR_RVK_PAGE } from 'constants/index';
import { useSeoMeta, useWebPageSchema, useMetaPage } from 'hooks';
import { getLocaleUrlPath, getAbsoluteMinfinLink } from 'utils';

import {
  Wrapper,
  Banner,
  MainInfoWrapper,
  LogoWrapper,
  Logo,
  Img,
  Name,
  ButtonsWrapper,
  LinkToReview,
  Icon,
} from './styles';

function OrganizationPageHeader({
  backgroundImage: banner,
  fullLogo,
  menu,
  slug,
  name,
  nameP,
  nameG,
  products,
}) {
  const { locale } = useIntl();

  const { pageTitle, metaTitle, metaDescription } = useSeoMeta(
    META_FOR_RVK_PAGE,
    {
      bank: nameP,
      nameG,
    }
  );

  const reviewHref = useMemo(
    () =>
      getAbsoluteMinfinLink(
        `${getLocaleUrlPath(locale)}/company/${slug}/review/add/`
      ),
    [locale, slug]
  );

  const meta = useMetaPage();

  const pageSchema = useWebPageSchema(
    {
      '@type': 'ItemList',
      name: pageTitle,
      itemListElement: products.slice(0, 6).map(el => {
        return {
          '@context': 'https://schema.org/',
          '@type': 'BankAccount',
          name: el.productName,
          provider: {
            '@type': 'BankOrCreditUnion',
            name,
          },
        };
      }),
    },
    metaTitle,
    metaDescription
  );

  const pageScripts = useMemo(
    () => [
      {
        type: 'application/ld+json',
        innerHTML: pageSchema,
      },
    ],
    [pageSchema]
  );

  return (
    <Wrapper>
      <PageHelmet
        pageTitle={pageTitle}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        meta={meta}
        script={pageScripts}
      />

      <Banner img={banner} />

      <MainInfoWrapper>
        <LogoWrapper>
          <Logo>
            <Img src={getAbsoluteMinfinLink(fullLogo)} />
          </Logo>

          <Name>{pageTitle}</Name>
        </LogoWrapper>

        <ButtonsWrapper>
          <LinkToReview href={reviewHref} rel="nofollow">
            <Icon name="plus" />

            <FormattedMessage id="addReview" />
          </LinkToReview>
        </ButtonsWrapper>
      </MainInfoWrapper>

      {!!menu && <ResponsiveMenuWrapper menu={menu} />}
    </Wrapper>
  );
}
OrganizationPageHeader.propTypes = {
  backgroundImage: PropTypes.string,
  fullLogo: PropTypes.string,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  slug: PropTypes.string,
  name: PropTypes.string,
  nameP: PropTypes.string,
  nameG: PropTypes.string,
};

OrganizationPageHeader.defaultProps = {
  backgroundImage: '',
  fullLogo: '',
  menu: [],
  products: [],
  slug: '',
  name: '',
  nameP: '',
  nameG: '',
};

export default memo(OrganizationPageHeader);
